<template>
  <div class="BusinessClearingList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
    >
      <template #searchSlot>
        <v-datepicker
          label="完成时间段"
          type="rangedatetimer"
          :maxDate="maxDate"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime">
        </v-datepicker>
        <v-input label="订单编号" v-model="searchParams.serialNumber"></v-input>
        <v-select2 label="所属商家" v-model="searchParams.busId" v-bind="shopParams"></v-select2>
        <v-select label="付款方式" v-model="searchParams.payType" :options="payTypeOps"></v-select>
        <v-select2
          v-if="getShowRegion"
          label="所属公司"
          :width="160"
          v-model="searchParams.regionId"
          v-bind="regionParams">
        </v-select2>
      </template>
    </list>
  </div>
</template>

<script>
import { regionParams, shopParams } from 'common/select2Params'
import { getListURL, exportListURL, getStoreSubjectURL, getPayTypeURL } from './api'
import { createAlinkVNode } from 'common/vdom'
import moment from 'moment'
import { mapHelper } from 'common/utils'

export default {
  name: 'BusinessClearingList',
  data () {
    return {
      maxDate: moment().format('YYYY-MM-DD HH:mm'),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      payTypeOps: [],
      regionParams: regionParams,
      shopParams: shopParams,
      storeSubjectParams: {
        searchUrl: getStoreSubjectURL,
        method: 'post',
        request: {
          text: 'subName',
          value: 'subId'
        }
      },
      searchParams: {
        startTime: this.showDefaultDate() ? moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm') : '',
        endTime: this.showDefaultDate() ? moment().format('YYYY-MM-DD HH:mm') : '',
        serialNumber: '',
        subId: '',
        busId: '',
        payType: undefined,
        regionId: ''
      },
      headers: [
        {
          prop: 'serialNumberVNode',
          label: '订单编号',
          minWidth: '100',
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.serialNumber,
              cb: this.look
            })
          }
        },
        {
          prop: 'busName',
          label: '所属商家'
        },
        {
          prop: 'payType',
          label: '付款方式'
        },
        {
          prop: 'totalMoney',
          label: '订单总额',
          formatter (row) {
            return row.totalMoney.toFixed(2)
          }
        },
        {
          prop: 'expensesMoney',
          label: '物流金额',
          formatter (row) {
            return row.expensesMoney.toFixed(2)
          }
        },
        {
          prop: 'redMoney',
          label: '红包金额',
          formatter (row) {
            return row.redMoney ? row.redMoney.toFixed(2) : '0.00'
          }
        },
        {
          prop: 'parperMoney',
          label: '抵价券金额',
          formatter (row) {
            return row.parperMoney.toFixed(2)
          }
        },
        {
          prop: 'realMoney',
          label: '实付金额',
          formatter (row) {
            return row.realMoney.toFixed(2)
          }
        },
        {
          prop: 'dealTime',
          label: '完成时间'
        },
        {
          prop: 'regionName',
          label: '所属公司',
          show: this.getShowRegion
        }
      ]
    }
  },

  // async created () {
  //   await this.$store.dispatch('mapStore/queryPayType')
  //   const { setPayTypeOps } = this.$store.getters['mapStore/getPayType']()
  //   this.payTypeOps = setPayTypeOps(1)
  // },

  computed: {
    // 根据账户角色权限，获取是否要显示所属公司
    getShowRegion () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && (userInfo.userType === '100' || userInfo.userType === '106')) {
          show = true
        }
      }
      return show
    }
  },

  mounted () {
    this.requestForGetPayType()
  },
  methods: {

    showDefaultDate () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && userInfo.userType !== '101') {
          show = true
        }
      }
      return show
    },
    look (row) {
      this.$router.push({
        name: 'businessClearingForm',
        query: {
          id: row.id
        }
      })
    },

    async requestForGetPayType () {
      let res = await this.$axios.get(getPayTypeURL)
      const { data, status } = res
      let typeList = []
      if (status === 100) {
        let list = data || []
        typeList = list.map(item => {
          return {
            text: item.payTypeDesc,
            value: item.payType
          }
        }) || []
      }
      const { setOps: typeOps } = mapHelper.setMap(typeList)
      this.payTypeOps = typeOps(1)
    }
  }
}
</script>
