// 获取列表
const getListURL = `${API_CONFIG.baseURL}orderAction!settlement.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}orderAction!settlementExport.action`
// 获取所属服务列表
const getStoreSubjectURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!viewSub.action`

// 查询详情
const queryURL = `${API_CONFIG.baseURL}orderAction!settlementDetail.action`
// 获取支付方式
const getPayTypeURL = `${API_CONFIG.butlerBaseURL}accountChannel/getPayTypeList?type=3`

export {
  getListURL,
  exportListURL,
  getStoreSubjectURL,
  queryURL,
  getPayTypeURL
}
